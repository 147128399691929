<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container bg flex-1 flex-v">
                <div class="pub-tt m15">
                    <div class="tabs">
                        <router-link to="/set-jiliangbiao" class="lk">计量表</router-link>
                        <router-link to="/set-sensor" class="lk">传感器</router-link>
                        <router-link to="/set-virtual" class="lk">虚拟表</router-link>
                        <router-link to="/set-outline" class="lk">离线表</router-link>
                        <router-link to="" class="lk cur">离线表文件</router-link>
                        <router-link to="/set-gaugesManage" class="lk">绑定数据点</router-link>
                    </div>
                    <div class="dix"></div>
                </div>
                <div class="p025 top-bar">
                    <el-form ref="form" class="top-form" :model="form">
                        <el-row :gutter="20">
                            <el-col :span="20">
                                <div class="flex flex-pack-justify">
                                    <el-upload class="avatar-uploader" :action="upload.uploadUrl" :headers="upload.headers"
                                        :show-file-list="false" :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload">
                                        <button class="ann annbg1 addbtn">添加文件</button>
                                    </el-upload>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="flex-1 flex-v p025">
                    <div class="table flex-1">
                        <el-table :data="tableData" height="100%" v-loading="loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)">
                            <el-table-column prop="id" width="60" label="ID">
                            </el-table-column>
                            <el-table-column prop="name" label="名称" width="260">
                            </el-table-column>
                            <el-table-column prop="des" label="状态"> </el-table-column>
                            <el-table-column prop="powerCategoryName" label="上传时间">
                            </el-table-column>
                            <el-table-column label="操作" width="150">
                                <template slot-scope="scope">
                                    <span class="lk c-red" @click="handleDelete(scope.$index, scope.row)">删除</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination @current-change="handleCurrentChange" v-model:current-page="pageInfo.pageNum"
                        layout="prev, pager, next, jumper,total" :total="pageInfo.total" :page-size="pageInfo.pageSize">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { outlineMeterFileList, delOutlineMeterFile } from "@/api/meter";
export default {
    data() {
        return {
            tableData: [],
            pageInfo: {
                total: 0,
                pageSize: 10,
                pageNum: 1,
            },
            loading: false,
            upload: {
                // 设置上传的请求头部
                headers: {
                    Authorization: sessionStorage.getItem("userToken"),
                },
                // 上传的地址
                uploadUrl: "/apis/cusGaugesOnlineFile/002-addFile",
            },
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    created() {
        this.getOutlineMeterFileList();
    },
    mounted() { },
    watch: {},
    methods: {
        handleEdit(index, row) {
            console.log(index, row);
        },
        handleDelete(index, row) {
            this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.tableData.splice(index, 1);
                    this.deleteOutlineMeterFile(row.id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                });
        },
        handleCurrentChange(val) {
            this.pageInfo.pageNum = val;
            this.getOutlineMeterFileList();
        },
        //获取离线表文件列表
        getOutlineMeterFileList() {
            this.loading = true;
            let param = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
                tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
            };
            outlineMeterFileList(param).then((res) => {
                if (res.data.code == 0) {
                    this.pageInfo.total = res.data.total;
                    this.tableData = res.data.rows;
                } else {
                    this.$message.warning(res.data.msg);
                }
                this.loading = false;
            });
        },
        //删除具体离线表文件
        deleteOutlineMeterFile(id) {
            let param = {
                ids: String(id),
            };
            delOutlineMeterFile(param).then((res) => {
                if (res.data.code == 0) {
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                } else {
                    this.$message.warning(res.data.msg);
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
.pop .detail {
    padding-right: 20px;
}
</style>
